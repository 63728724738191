import confettiAnmation from "assets/animations/confetti-animation.json";
import ChatBot from "components/_Shared/Chatbot/Chatbot";
import ModalBannerPremium from "components/_Shared/ModalBanner/Banners/ModalBannerPremium/ModalBannerPremium";
import CardHeader from "components/MaterialCards/CardHeader/CardHeader";
import ProgressBar from "components/MaterialCards/ProgressBar/ProgressBar";
import { useChatbot } from "context/ChatbotContext";
import { useMaterialCards } from "context/MaterialCardsContext";
import { useModalBanner } from "context/ModalBannerContext";
import { UserContext } from "context/UserContext";
import useRequest from "hooks/useRequest";
import React, { useContext, useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useHistory, useParams } from "react-router-dom";
import { MATERIALS_CARDS_FEATURE_ENABLED } from "utils/features";
import { logAnalyticsEvent } from "utils/firebase-analytics";

import Audio from "./Audio/Audio";
import Conversation from "./Conversation/Conversation";
import Essay from "./Essay/Essay";
import FinishedCategory from "./FinishedCategory/FinishedCategory";
import MultipleAnswer from "./MultipleAnswer/MultipleAnswer";
import Pontuation from "./Pontuation/Pontuation";
import RenderAD from "./RenderAD/RenderAD";
import RenderHTML from "./RenderHTML/RenderHTML";
import ShortsVideo from "./ShortsVideo/ShortsVideo";
import * as S from "./styles.module.css";
import VoiceBay from "./VoiceBay/VoiceBay";

const cardsDic = {
  ESSAY: Essay,
  MULTI_CHOICE: MultipleAnswer,
  HTML: RenderHTML,
  SHORTS: ShortsVideo,
  AUDIO: Audio,
  VOICE: VoiceBay,
  PONTUATION: Pontuation,
  FINISHED: FinishedCategory,
  RENDERAD: RenderAD,
  CONVERSATION: Conversation,
};

const cardsToHideHeader = ["SHORTS", "PONTUATION", "FINISHED"];

const MaterialCards = () => {
  const history = useHistory();

  const { request } = useRequest();
  const [cardKind, setCardKind] = useState(null);
  const [cardUuid, setCardUuid] = useState(null);
  const [unityId, setUnityId] = useState("");
  const [finishedCategoryRender, setFinishedCategoryRender] = useState(false);
  const [showAnimation, setShowAnimation] = useState(true);
  const [isLoadingSaveAnswer, setIsLoadingSaveAnswer] = useState(false);
  const [cardPosition, setCardPosition] = useState(0);

  const [shortAdsToShow, setShortAdsToShow] = useState(0);
  const [shortsAds, setShortsAds] = useState([]);

  const { updateUserContext, user, isUserExpired } = useContext(UserContext);
  const { showBanner, handleHideBanner } = useModalBanner();
  const { handleChatbot } = useChatbot();

  const isMobile = window.innerWidth < 768;

  const { initialCardPosition } = useParams();

  const {
    setQuestion,
    setPercentage,
    setUnityTitle,
    setProgress,
    unityTitle,
    progress,
    setCategoryProgress,
  } = useMaterialCards();

  const renderCard = (card) => {
    setShowAnimation(false);

    const { question, uuid, unity, position, kind } = card;

    setUnityTitle(unity.title);

    handleChatbot("neutral", "");

    setCardKind(kind);
    setQuestion(question);
    setCardUuid(uuid);
    setCardPosition(position);
  };

  const handleSetUnityProgress = (done, total) => {
    const progress = ((done / total) * 100).toFixed();
    setProgress(progress);
  };

  const pushAd = () => {
    try {
      const insElement = document.getElementById("eb-card-slot");

      if (!insElement) {
        console.log("1");
        window.adsbygoogle.push({});
        return;
      }

      insElement.removeAttribute("data-adsbygoogle-status");
      insElement.removeAttribute("data-ad-status");
      insElement.innerHTML = "";
      window.adsbygoogle.push({});
      console.log("2");
    } catch (e) {
      console.error("Error while pushing ad: ", e);
    }
  };

  const getCardByPosition = async (position) => {
    const response = await request("GET", `/card/position/${position}`);

    renderCard(response);
    setProgress(0);
    setUnityId(response.unity.uuid);

    return;
  };

  const getActualCard = async (levelUpCategory) => {
    if (finishedCategoryRender && levelUpCategory) {
      setShowAnimation(false);
      setFinishedCategoryRender(false);
      setCardKind("FINISHED");
      return;
    }

    const user = await updateUserContext();

    if (!MATERIALS_CARDS_FEATURE_ENABLED.includes(user.levelTest)) {
      history.push("/");
      return;
    }

    const { currentCard, unity } = await request("GET", "/card/getCurrentProgress");

    renderCard(currentCard);

    handleSetUnityProgress(unity.done, unity.total);
    setUnityId(currentCard.unity.uuid);
  };

  const nextCard = async (answer) => {
    if (isLoadingSaveAnswer) {
      return;
    }

    if (!cardUuid) {
      getActualCard();
    }

    const isActiveStudent = user?.student?.status === "ACTIVE";

    if (!isActiveStudent && shortsAds[cardPosition]) {
      const { url, title } = shortsAds[cardPosition];

      setCardKind("SHORTS");
      setQuestion({
        url: url,
        title: title,
        isAd: true,
      });
      setUnityTitle("Vídeo");
      setCardPosition(cardPosition + 1);
      return;
    }

    setIsLoadingSaveAnswer(true);

    setShortAdsToShow(shortAdsToShow + 1);
    localStorage.setItem("shortAdsToShow", shortAdsToShow + 1);

    const { nextCard, finishedUnity, unityStats, categoryStats, finishedCategory, unityProgress } = await request(
      "POST",
      `/card/${cardUuid}/saveAnswer`,
      answer,
    ).catch((error) => {
      setIsLoadingSaveAnswer(false);
      throw error;
    });

    logAnalyticsEvent("card_answer", {
      unity_title: unityTitle,
      is_correct: answer.isCorrect,
      card: nextCard.position - 1,
      kind: cardKind,
    });

    renderCard(nextCard);

    setIsLoadingSaveAnswer(false);

    if (finishedCategory) {
      setFinishedCategoryRender(true);
      const percentage = ((categoryStats.correct / categoryStats.total) * 100).toFixed();
      setCategoryProgress(percentage);
    }

    if (finishedUnity) {
      const percentage = ((unityStats.correct / unityStats.total) * 100).toFixed();
      setPercentage(percentage);
      setCardKind("PONTUATION");

      logAnalyticsEvent("unity_completed", {
        unity_title: unityTitle,
        score: percentage,
      });

      if (percentage >= 70) {
        setShowAnimation(true);
      }

      return;
    }

    setProgress(unityProgress);
  };

  const resetProgress = async () => {
    await request("POST", `/unity/${unityId}/resetProgress`);
  };

  const getAdsPosition = async () => {
    const adPosition = await request("GET", "/card/getAdPositions");

    setShortsAds(adPosition);
  };

  const Card = cardsDic[cardKind];

  const ContainerStyle = cardKind !== "SHORTS" ? S.container : S.containerShorts;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: confettiAnmation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    handleChatbot("neutral", "");

    if (initialCardPosition) {
      getCardByPosition(initialCardPosition);
    } else {
      getActualCard();
    }

    getAdsPosition();

    const shortAdsToShow = localStorage.getItem("shortAdsToShow");
    setShortAdsToShow(parseInt(shortAdsToShow) || 0);

    const interval = setInterval(pushAd, 30000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isUserExpired) {
      history.push("/");
    }
  }, [isUserExpired]);

  if (Card) {
    return (
      <>
        {showBanner && <ModalBannerPremium handleHideBanner={handleHideBanner} />}

        <div className={showAnimation && "flex"}>
          {showAnimation && (
            <div className={S.animation}>
              <Lottie isClickToPauseDisabled={true} options={defaultOptions} speed={0.8} />
            </div>
          )}

          <div className={ContainerStyle}>
            {!cardsToHideHeader.includes(cardKind) && (
              <div className={S.wrapperHeader}>
                <CardHeader unityName={unityTitle} />
                <ProgressBar width={progress} variant="question" />
              </div>
            )}

            <Card getActualCard={getActualCard} nextCard={nextCard} resetProgress={resetProgress} />
          </div>

          {showAnimation && !isMobile && (
            <div className={S.animation}>
              <Lottie isClickToPauseDisabled={true} options={defaultOptions} speed={0.8} />
            </div>
          )}
        </div>

        <ChatBot />
      </>
    );
  }

  return <div>Carregando...</div>;
};

export default MaterialCards;
